// TODO: Create a custom and proper error page

const ErrorPage = () => {
  return (
    <div>
      <h1>404 Error</h1>
      <p>Page not found</p>
    </div>
  );
};

export default ErrorPage;
